import React from "react"
import Layout from "../components/common/layout"

const CommbNews = () => {
  return <Layout>
      
  </Layout>
}

export default CommbNews
